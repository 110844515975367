import { useMediaQuery } from '@vueuse/core';
import {
  desktopMinWidth,
  mobileMaxWidth,
  smallMobileMaxWidth,
  tabletMaxWidth,
  tabletMinWidth,
} from 'theme/assets/styles/export.module.scss';
import type { WatchStopHandle } from 'vue';

const maxWidth = (width: string) => `(max-width: ${width})`;
const minWidth = (width: string) => `(min-width: ${width})`;
const and = (...predicates: string[]) => predicates.join(' and ');

// mounted前に評価するとSSRとclientでテンプレートに差分が起きてエラーが起きる。
// そのため、mounted後にdevice typeの評価をするように遅延する。
const syncIfAppRunningOnClient = <T>(src: Ref<T>, dst: Ref<T>) => {
  let unwatch: Maybe<WatchStopHandle>;
  onMounted(() => {
    unwatch = watch(src, (value) => (dst.value = value), { immediate: true });
  });
  onUnmounted(() => !!unwatch && unwatch());
  return dst;
};

export const rawDeviceType = () => ({
  isSmallMobile: useMediaQuery(maxWidth(smallMobileMaxWidth)),
  isMobile: useMediaQuery(maxWidth(mobileMaxWidth)),
  isTablet: useMediaQuery(
    and(minWidth(tabletMinWidth), maxWidth(tabletMaxWidth))
  ),
  isTabletOrDesktop: useMediaQuery(minWidth(tabletMinWidth)),
  isDesktop: useMediaQuery(minWidth(desktopMinWidth)),
});

export const useDeviceType = () => {
  const {
    isSmallMobile: isSmallMobileRaw,
    isMobile: isMobileRaw,
    isTablet: isTabletRaw,
    isTabletOrDesktop: isTabletOrDesktopRaw,
    isDesktop: isDesktopRaw,
  } = rawDeviceType();
  const isSmallMobile = syncIfAppRunningOnClient(isSmallMobileRaw, ref(false));
  const isMobile = syncIfAppRunningOnClient(isMobileRaw, ref(false));
  const isTablet = syncIfAppRunningOnClient(isTabletRaw, ref(false));
  const isTabletOrDesktop = syncIfAppRunningOnClient(
    isTabletOrDesktopRaw,
    ref(false)
  );
  const isDesktop = syncIfAppRunningOnClient(isDesktopRaw, ref(false));
  return {
    isSmallMobile,
    isMobile,
    isTablet,
    isTabletOrDesktop,
    isDesktop,
  };
};
